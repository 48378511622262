import React from 'react';
import Button from '@material-ui/core/Button';
import {BrowserRouter, Route, Link} from 'react-router-dom';
import VideoPlayer from "./VideoPlayer";

const PageOne = () => {
  return <div><Link to="/two">About</Link><span>Page One</span></div>;
}

const PageTwo = () => {
  return (
    <>
    <Link to="/">About</Link>
    <VideoPlayer src="https://lundoor.com/BigBuckBunny.mp4" controls={true} autoplay={false} />
    </>
  );
}

function App() {
  return (
    <div className="App">
      <Button variant="contained" color="primary">
        Hello World
    </Button>
    <BrowserRouter>
      <Route exact path="/"><PageOne /></Route>
      <Route path="/two"><PageTwo /></Route>
    </BrowserRouter>
    </div>
  );
}

export default App;
