import { useRef, useState, useEffect } from 'react';
import videojs from 'video.js';

const usePlayer = ({ src, controls, autoplay }) => {
    const options = {
      fill: true,
      fluid: true,
      preload: 'auto',
      html5: {
        hls: {
          enableLowInitialPlaylist: true,
          smoothQualityChange: true,
          overrideNative: true,
        },
      },
    };
    const videoRef = useRef(null);
    const [player, setPlayer] = useState(null);
  
    useEffect(() => {
      const vjsPlayer = videojs(videoRef.current, {
        ...options,
        controls,
        autoplay,
        sources: [src],
      });
      setPlayer(vjsPlayer);
  
      return () => {
        if (player !== null) {
          player.dispose();
        }
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
      if (player !== null) {
        player.src({ src });
      }
    }, [src]); // eslint-disable-line react-hooks/exhaustive-deps
  
    return videoRef;
  };
  
 export default usePlayer; 